import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ComponentSwitch from "../components/ComponentSwitch"
import { graphql } from 'gatsby'
import {
  TextBlockFragment,
  HeroImageAndTextFragment,
  FiftyFiftyFragment,
  ThreeIconFragment
} from '../fragments/prismicFragment'


const IndexPage = props => {

  const data = props.data.prismicPage.data;
  return (
    <Layout location={props.location}>
      <SEO
        title={data.title && data.title.richText[0].text}
        description={data.meta_description && data.meta_description}
      />
      <div className="ContentPage">
        <div className="MainPageSection">
          {data && data.body && <ComponentSwitch components={data.body} location={props.location} />}
        </div>
      </div>
    </Layout>
  )
}



export const query = graphql`
query PrismicIndexPageData{
    prismicPage(uid: {eq:"home"}) {
      uid
        data{
            
            meta_description
            title{
              richText
            }
            body{
            ... on PrismicPageDataBodyText {
              ...TextBlockFragment
            }

            ... on PrismicPageDataBodyHeroImageAndText{
              ...HeroImageAndTextFragment
            }

            ... on PrismicPageDataBodyFiftyFifty{
              ...FiftyFiftyFragment
            }

            ... on PrismicPageDataBodyThreeIcon{
              ...ThreeIconFragment
            }

          }
        }
      }
    
  }
`
export default IndexPage

IndexPage.fragments = [
  TextBlockFragment,
  HeroImageAndTextFragment,
  FiftyFiftyFragment,
  ThreeIconFragment
];